import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {get, round, slice, head, isNil, includes} from "lodash"
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {useGetAllQuery} from "../../../hooks/api";
import CustomDatepicker from "../../../components/datepicker";
import dayjs from "dayjs";
import Modal from "../../../components/modal";
import InputSearch from "../../../components/input";
import MaskedInput from "../../../components/masked-input";
import {Search} from "react-feather";


const PractitionersContainer = () => {
    const {t} = useTranslation()
    const [filterTemp, setFilterTemp] = useState({pin: null, name: null, patient_pin: null})
    const [filter, setFilter] = useState({pin: null, name: null, patient_pin: null})
    const [row, setRow] = useState(null)
    const {data: dateList} = useGetAllQuery({
        key: KEYS.months, url: URLS.months,
    })
    const columns = [
        {
            title: t('НАИМЕНОВАНИЕ'),
            key: 'fullname',
            render: ({value, row}) => <div className={'relative'}>
                <span className={'px-3'}>{value}</span>
                <span className={'absolute text-[#DC5454] text-xs  -bottom-3 right-3'}>{get(row, 'pin')}</span>
            </div>
        },
        {
            title: t('Население'),
            key: 'name',
            render: ({row}) => <div>
                <div
                    className={''}><NumericFormat
                    thousandSeparator={' '} displayType={'text'} value={round(get(row, 'count', 0), 2)}/>
                </div>
                <div className={' '}><NumericFormat className={'text-[#2F68FC] text-[12px]'} suffix={' '}
                                                    thousandSeparator={' '} displayType={'text'}
                                                    value={round(get(row, 'male_count', 0), 2)}/>
                    /
                    <NumericFormat className={'text-[#F25886] text-[12px]'} prefix={' '} thousandSeparator={' '}
                                   displayType={'text'}
                                   value={round(get(row, 'female_count', 0), 2)}/>
                </div>
            </div>
        },
        {
            title: t('Организация'),
            key: 'organization',
            render: ({value}) => <span className={'px-3'}>{value}</span>
        },
        {
            title: t('Район'),
            key: 'city',
            render: ({value}) => <span className={'px-3'}>{value}</span>
        },
        {
            title: t('Регион'),
            key: 'state',
            render: ({value}) => <span className={'px-3'}>{value}</span>
        }
    ]

    useEffect(() => {
        if (dateList) {
            setFilter(prev => ({...prev, month: `${head(get(dateList, 'data', []))}`}))
        }
    }, [dateList]);

    console.log('filterTemp',filterTemp)
    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-12">
                    <Title>{t("Медицинские работники")}</Title>
                </div>
                <div className="col-span-12 mt-8">
                    <GridView
                        onRowClick={(val) => setRow(val)}
                        hideError
                        doubleRow
                        params={{
                            pin: get(filter, 'pin'),
                            name: get(filter, 'name'),
                            patient_pin: get(filter, 'patient_pin')
                        }}
                        filters={<div className={'grid grid-cols-12 mb-5 gap-x-6 '}>
                            <div className="col-span-3">
                                <InputSearch timeout={0}
                                             setValue={(val) => setFilterTemp(prev => ({...prev, name: val}))}
                                             value={get(filterTemp, 'name')} placeholder={t('Search by name')}/>
                            </div>
                            <div className="col-span-2">
                                <MaskedInput timeout={0} mask={'99999999999999'}
                                             setValue={(val) => setFilterTemp(prev => ({...prev, pin: val}))}
                                             value={get(filterTemp, 'pin')} placeholder={t('Search by pin')}/>
                            </div>
                            <div className="col-span-2">
                                <MaskedInput timeout={0} mask={'99999999999999'} maskChar={null}
                                             setValue={(val) => setFilterTemp(prev => ({...prev, patient_pin: val}))}
                                             value={get(filterTemp, 'patient_pin')}
                                             placeholder={t('Search by patient pin')}/>
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-end w-48  mb-0 relative  mt-1">
                                    <CustomDatepicker
                                        defaultValue={dayjs(get(filter, 'month')).toDate()}
                                        handleDate={(val) => setFilter(prev => ({
                                            ...prev,
                                            month: dayjs(val).format('YYYYMM')
                                        }))}
                                        includeDates={get(dateList, 'data', []).map(item => `${slice(`${item}`, 0, 4).join('')}/${slice(`${item}`, 4).join('')}`)}
                                        property={{showMonthYearPicker: true}} dateFormat="MM/yyyy"/>
                                </div>
                            </div>
                            <div className="col-span-4 text-right">
                                <div className="flex items-center justify-end">
                                    <button onClick={()=>{
                                        setFilterTemp({pin: null, name: null, patient_pin: null})
                                        setFilter({pin: null, name: null, patient_pin: null,month: `${head(get(dateList, 'data', []))}`})
                                    }} className={'bg-red-300 py-3.5 px-7 rounded-lg font-semibold text-center h-12  mt-1'}>{t("Очистить фильтры")}</button>
                                    <button onClick={() => setFilter(prev => ({...prev, ...filterTemp}))}
                                            className={'min-w-[125px] py-3.5 px-7 rounded-lg bg-primary inline-flex items-center  text-white justify-center font-semibold text-center h-12 ml-5 mt-1'}>
                                        <Search className={'mr-1.5'} size={22}/> {t('Search')}
                                    </button>

                                </div>
                            </div>

                        </div>}
                        listKey={KEYS.practitioner} url={`${URLS.practitioner}?month=${get(filter, 'month')}`}
                        columns={columns}/>
                </div>
                <Modal onClose={() => {
                    setRow(null);
                }}
                       classNames={'!max-h-[90vh] relative !w-[1180px]'}
                       title={get(row, 'fullname')}
                       open={!isNil((row))}>
                    <GridView
                        columns={[

                            {
                                title: t('ФИО'),
                                key: 'full_name',
                            },
                            {
                                title: t('ПИНФЛ'),
                                key: 'pin',
                            },
                            {
                                title: t('Дата рождения'),
                                key: 'birth_date',
                                render: ({value}) => dayjs(value).format("DD-MM-YYYY")
                            },
                            {
                                title: t('Дата прикрепления'),
                                key: 'patient_attached',
                                render: ({value}) => dayjs(value).format("DD-MM-YYYY")

                            }]}
                        params={{
                            pin: get(filter, 'patient_pin')?.length > 13 && !includes(get(filter, 'patient_pin'),'_') ? get(filter, 'patient_pin') : undefined,
                        }}
                        listKey={[KEYS.practitioner, get(row, 'practitioner_id')]}
                        url={`${URLS.practitioner}/${get(row, 'practitioner_id')}/${get(filter, 'month')}/patients`}
                    />

                </Modal>
            </div>
        </div>
    );
};

export default PractitionersContainer;