import React, {useMemo} from 'react';
import clsx from "clsx";
import {debounce} from "lodash";
import InputMask from 'react-input-mask';

const MaskedInput = ({
                         timeout = 1000, type = 'search', label = '', value = '', setValue = () => {
    }, placeholder = '', classNames = '', mask = 'aa', maskChar = '_', ...rest
                     }) => {
    const handleChange = useMemo((e) => debounce((e) => {
        setValue(e.target.value)
    }, timeout), [])
    return (
        <>
            <div className={clsx("form-group mb-0", classNames)}>
                <label className={'form-label'} htmlFor="#">{label}</label>
                <InputMask  type={type} mask={mask}
                           maskChar={maskChar} placeholder={placeholder} defaultValue={value}
                           onChange={handleChange}
                           className={clsx('form-input')}
                />
            </div>
        </>
    );
};

export default MaskedInput;