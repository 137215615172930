import React, {useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import InputSearch from "../../../components/input";
import MaskedInput from "../../../components/masked-input";
import {Search} from "react-feather";
import Select from "../../../components/select";
import {useGetAllQuery} from "../../../hooks/api";
import {useNavigate} from "react-router-dom";


const ProlongedCasesContainer = () => {
    const [filterTemp, setFilterTemp] = useState({pin: null, name: null,organization_id:null,diagnosis:null})
    const [filter, setFilter] = useState({pin: null, name: null,organization_id:null,diagnosis:null})
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {data:organizations,isLoading:isLoadingOrganizations} = useGetAllQuery({key:KEYS.organizations,url:URLS.organizations})
    const {data:diagnoses,isLoading:isLoadingDiagnose} = useGetAllQuery({key:KEYS.icd10,url:URLS.icd10})
    const columns = [
        {
            title: 'ID',
            key: 'form_066_id', classnames: '!w-[80px] !min-w-0'
        },
        {
            title: t('Patient'),
            key: 'patient_id',
        },
        {
            title: t('pinfl'),
            key: 'pinfl',
        },
        {
            title: t('diagnosis'),
            key: 'diagnosis',
        },
        {
            title: t('paid_source'),
            key: 'paid_source',
        },
        {
            title: t('organization'),
            key: 'organization',
        },
    ]


    return (<div>
        <div className="grid grid-cols-12 items-center">
            <div className="col-span-12">
                <Title>{t("Prolonged cases")}</Title>
            </div>
            <div className="col-span-12 mt-8">
                <GridView
                    onRowClick={(val)=>navigate(`/hospitals/prolonged-cases/form66/2023/11/${get(val,'form_066_id')}`)}
                    params={{
                        pin: get(filter, 'pin'),
                        name: get(filter, 'name'),
                        organization_id: get(filter, 'organization_id.value'),
                        diagnosis: get(filter, 'diagnosis.value'),
                    }}
                    filters={<div className={'grid grid-cols-12 mb-5 gap-x-6 '}>
                        <div className="col-span-3">
                            <InputSearch timeout={0}
                                         setValue={(val) => setFilterTemp(prev => ({...prev, name: val}))}
                                         value={get(filterTemp, 'name')} placeholder={t('Search by name')}/>
                        </div>
                        <div className="col-span-2">
                            <MaskedInput timeout={0} mask={'99999999999999'}
                                         setValue={(val) => setFilterTemp(prev => ({...prev, pin: val}))}
                                         value={get(filterTemp, 'pin')} placeholder={t('Search by pin')}/>
                        </div>
                        <div className="col-span-3 pt-1">
                            <Select
                                isLoading={isLoadingOrganizations}
                                placeholder={t("Select organization")}
                                value={get(filterTemp, 'organization_id')}
                                setValue={(val) => setFilterTemp((prev) => ({...prev, organization_id: val}))}
                                options={get(organizations,'data.data',[]).map(item=>({value:get(item,"id"),label:get(item,"name")}))}/>
                        </div>
                        <div className="col-span-3 pt-1">
                            <Select
                                isLoading={isLoadingDiagnose}
                                placeholder={t("Select diagnose")}
                                value={get(filterTemp, 'diagnosis')}
                                setValue={(val) => setFilterTemp((prev) => ({...prev, diagnosis: val}))}
                                options={get(diagnoses,'data.data',[]).map(item=>({value:get(item,"code"),label:`${get(item,"code")} ${get(item,"display")}`}))}/>
                        </div>
                        <div className="col-span-1 text-right">
                            <div className="flex items-center justify-end">
                                {/*<button onClick={()=>{*/}
                                {/*    setFilterTemp({pin: null, name: null})*/}
                                {/*    setFilter({pin: null, name: null})*/}
                                {/*}} className={'bg-red-300 py-3.5 px-7 rounded-lg font-semibold text-center h-12  mt-1'}>{t("Очистить фильтры")}</button>*/}
                                <button onClick={() => setFilter(prev => ({...prev, ...filterTemp}))}
                                        className={'min-w-[125px] py-3.5 px-7 rounded-lg bg-primary inline-flex items-center  text-white justify-center font-semibold text-center h-12 ml-5 mt-1'}>
                                    <Search className={'mr-1.5 flex-none'} size={18}/> {t('Search')}
                                </button>

                            </div>
                        </div>

                    </div>}
                    listKey={KEYS.allInpatientReports}
                    url={URLS.allInpatientReports}
                    columns={columns}/>
            </div>
        </div>
    </div>);
};

export default ProlongedCasesContainer;