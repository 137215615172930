import React from 'react';
import ProlongedCasesContainer from "../containers/ProlongedCasesContainer";

const ProlongedCasesPage = () => {
    return (
        <>
            <ProlongedCasesContainer/>
        </>
    );
};

export default ProlongedCasesPage;